import * as bootstrap from "bootstrap";


document.addEventListener('DOMContentLoaded', function () {
  var bootstrapTooltipAllowList = bootstrap.Tooltip.Default.allowList;
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl, { sanitize: false, html: true })
  })

  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
  })

  var dropdownElementList = [].slice.call(document.querySelectorAll('[data-bs-toggle="dropdown"]'))
  var dropdownList = dropdownElementList.map(function (dropdownToggleEl) {
    return new bootstrap.Dropdown(dropdownToggleEl)
  })

  var dataSpyList = [].slice.call(document.querySelectorAll('[data-bs-spy="scroll"]'))
  dataSpyList.forEach(function (dataSpyEl) {
    const target = dataSpyEl.getAttribute('data-bs-target');
    const offset = parseInt(dataSpyEl.getAttribute('data-bs-offset'));

    bootstrap.ScrollSpy.getOrCreateInstance(document.body, { target: target, offset: offset });
  });

  var triggerTabList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tab"]'));
  var tabList = triggerTabList.map(function (triggerEl) {
    var tabTrigger = new bootstrap.Tab(triggerEl)

    triggerEl.addEventListener('click', function (event) {
      event.preventDefault()
      tabTrigger.show()
    })
  });

  const navbarToggler = document.querySelector('.navbar-toggler');
  const navbar = document.querySelector('.navbar');

  navbarToggler.addEventListener('click', function () {
    navbar.classList.toggle('navbar-dark');
    navbar.classList.toggle('bg-white');
    navbar.classList.toggle('navbar-light');
  });

  const header = document.querySelector('header');

  // create an IntersectionObserver to observe the jumbotron element
  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (!entry.isIntersecting) {
        navbar.classList.remove('navbar-dark');
        navbar.classList.add('bg-white');
        navbar.classList.add('navbar-light');
      } else {
        navbar.classList.remove('navbar-light');
        navbar.classList.remove('bg-white');
        navbar.classList.add('navbar-dark');
      }
    });
  });

  // start observing the jumbotron element
  observer.observe(header);

});

